// import React from 'react'

// function TermsCondtios() {
//     return (
//         <div>TermsCondtios</div>
//     )
// }

// export default TermsCondtios

import React, { useEffect, useState } from "react";
import Logo from "./asserts/images/full-logo.png";
import Footer from "./asserts/images/footer.png";
import BannerLogo from "./asserts/images/terms.png";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";
import { MdPolicy, MdPrivacyTip } from "react-icons/md";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import Flag from "./asserts/images/flag.png";
import { Link } from "react-router-dom";

let container = "max-w-screen-2xl m-auto p-6";
let title = "text-6xl font-black py-10";
let sub_title = "text-4xl";



export const TermsCondtios = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <main>
            <section className="fixed bg-black w-full z-50">
                <p className="text-2xl text-white text-center p-2 flex items-center justify-center gap-3 flex-warp">
                    <img src={Flag} alt="flag" width={30} />
                    <span>Parents who are based in India get additional 50% off</span>
                </p>
            </section>
            <section className="banner">
                <div className={container}>
                    <div className="items-center w-full flex justify-between mt-10 ">
                        <div></div>
                        <div className="max-w-52  justify-end">
                            <img src={Logo} alt="Logo" />
                        </div>
                    </div>
                    <h3
                        className={`text-white text-5xl text-center font-medium tracking-wide mb-20`}
                    >
                        Terms & Conditions
                    </h3>
                    {/* <div className="flex justify-between py-20 items-center">
                        <div>
                            <h3
                                className={`${title} text-white text-8xl font-medium banner-title`}
                            >
                                Terms &<br className="break-tag"></br>Conditions!
                            </h3>

                        </div>
                        <div className="max-w-md logo">
                            <img src={BannerLogo} alt="img" />
                        </div>
                    </div> */}
                </div>
            </section>
            <section>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        TERMS & CONDITIONS
                    </h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-xl`} >
                        The website <Link to={"/"}
                            href="https://awetisminsights.com/"
                            style={{
                                color: "#A7C837",
                                cursor: 'pointer',
                                textDecoration: "underline"
                            }}
                        > awetismisights.com</Link> is owned and operated by Reena Singh.
                        Before you proceed further with your decision of availing any of the services of Khushi Therapy Center, kindly take a moment to read all the Terms and Conditions.
                        These Terms and conditions apply to all visitors, users and others who access or use our Services.
                        By accessing or using the Service you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms then you may not access the Service.
                    </div>
                    <div className={`text-xl`}>
                        Khushi works with families especially those who have a diagnosis of Autism and sensory processing challenges.
                        In this we provide online and offline therapy sessions, provide training, courses to parents and professionals on various aspects.
                        All the information contained in this Website is for general information purpose only.
                        While Khushi endeavor to keep the information up-to-date and correct, we make no representation or warranty of any kind, express or implied about the completion, accuracy, reliability, sustainability or availability with respect to website or information,
                        products, services or related graphics, contained on the website for any purpose.
                        Any reliance you place on such information is, therefore, strictly at your own risk.
                    </div>
                    <div className={`text-xl`}>
                        Khushi Management reserves the right to deny admission to their training,
                        workshops or therapy without any justification.
                    </div>
                </div>
            </section>

            <section>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        COPYRIGHTS AND TRADEMARKS
                    </h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-xl`} >
                        Unless stated otherwise, all the copyrights to the intellectual information shared on the website,
                        inclusive of but not limited to, audio, video, text, and/or graphical data, are the sole property of Reena Singh, and are guided by the pertinent Indian Laws.
                        Any infringement or attempts of infringements, of the same would be punishable by the law.
                    </div>

                </div>
            </section>

            <section>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        DISCLAIMER
                    </h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-xl`} >
                        The information on <Link to={"/"}
                            href="https://awetisminsights.com/"
                            style={{
                                color: "#A7C837",
                                cursor: 'pointer',
                                textDecoration: "underline"
                            }}
                        > awetismisights.com</Link> is for the general information of the authorized recipient, and we are not soliciting any action based upon it.
                        The services are designed to provide helpful information for the child with special needs and is not intended to cure, or prevent any condition or disease.
                        The therapist makes no guarantee concerning the level of success your child may experience by following the exercises. You accept the risk that results will differ for each individual depending upon the background, genetics,
                        previous medical history, application of exercises,
                        posture and various other mental and physical factors. Significant injury risk is possible if you do not follow due diligence.
                        Application of the exercises and information shared, in any particular situation, remains the responsibility of the parents.
                        Parents are requested to approach a therapist or medical practitioner immediately if the child experiences any pain or in case of a change in the behavioural nature of the child.
                        The therapist / Khushi Therapy Centre is not responsible and hereby disclaims any liability arising out of or in connection with any loss, damage, disorder caused, or for any consequences from the use or the application of exercises and information, and makes no warranty, expressed or implied.
                    </div>

                </div>
            </section>

            {/* <section>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        PRIVACY POLICY
                    </h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-xl`} >
                        All personal information, which is gathered and stored through this site, shall be governed by these terms laid out by Khushi Therapy Center.
                        We capture personal information about the proposed user via contact form or while registering in any of our service.
                        The information is used for promptly identifying you and to attend personally to your specific requests.
                        We will hold your personal information confidentially and will preserve it. We will not sell or rent the information to anyone.
                        In case of a compulsion to share your information, we shall obtain your prior consent and the information shall be shared with only those people or companies who are authorized by you to do so.
                    </div>

                </div>
            </section> */}

            <section>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        Cancellation and Refund policy
                    </h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-3xl`} >
                        We try to avoid cancellation however if certain situations those are avoidable.
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Cancellation policy for Appointment
                    </div>
                    <div className={`text-xl`} >
                        <p>1. In the event of cancellation by therapist, full refund will be given or rescheduling of appointment will be done.</p>
                        <p>2. Same day cancellation by parents are not allowed. In case of same day cancellation or no show full therapy charges will be deducted.</p>
                        <p>3. Full refund or rescheduling of appointment will be done if the parent cancel the appointment a day before the appointment date.</p>
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Cancellation policy for Trainings.
                    </div>
                    <div className={`text-xl`} >
                        <p>1. In the event of cancellation by therapist, if the training is for a single day then either refund or rescheduling will be done.</p>
                        <p>2. If the training is multiday and if whole training is getting cancelled by therapist then either refund or rescheduling will be done.</p>
                        <p>3. If the training is multiday and cancellation is done for a some specific days then those many days will be rescheduled. There will be no refund in this case.</p>
                        <p>4. If the parent cancels on same day or no show then there will be no refund or no rescheduling will be done.</p>
                        <p>5. If the parent cancels his training at least 15 days prior to training date then he will be given full refund after that refund will be given only if someone else registers instead of him.</p>
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Cancellation policy for recorded courses.
                    </div>
                    <div className={`text-xl`} >
                        <p>1. Cancellation and refund is not possible once the course is purchased.</p>
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Cancellation policy for books and therapy swing.
                    </div>
                    <div className={`text-xl`} >
                        <p>1. If we are unable to ship the product then we provide full refund.</p>
                        <p>2. If the customer cancels the order before shipping then refund initiated after deducting 5% processing charges.</p>
                        <p>3. No refund will be allowed after that.</p>
                        <p>4. If the faulty or damaged product is received then we ship another product after receiving faulty product.</p>
                    </div>

                </div>
            </section>

            <section style={{ background: "#000" }} className="mt-20">
                <div className={`${container} py-36`}>
                    <div className="flex justify-between flex-wrap">
                        <a href="/">
                            <div className="max-w-52 py-8">
                                <img src={Logo} alt="Logo" />
                            </div>
                        </a>
                        <div className="py-8" style={{ color: "#fff" }}>
                            <h1 className="text-xl font-medium">Contact us</h1>
                            <div className="text-xl pt-4 flex gap-2 items-center">
                                <IoMailSharp />
                                <a href="mailto:info@awetisminsights.com">
                                    info@awetisminsights.com
                                </a>
                            </div>
                        </div>
                        <div className="py-8" style={{ color: "#fff" }}>
                            <h1 className="text-xl font-medium">Policy</h1>
                            <div className="flex flex-col">
                                <div className="text-xl pt-4 flex gap-2 items-center">
                                    <IoMailSharp />
                                    <Link to={"/terms"}>
                                        Terms & Conditions
                                    </Link>
                                </div>
                                <div className="text-xl pt-4 flex gap-2 items-center">
                                    <MdPrivacyTip />
                                    <Link to={"/privacy"}>
                                        Privacy Policy
                                    </Link>
                                </div>
                                <div className="text-xl pt-4 flex gap-2 items-center">
                                    <MdPolicy />
                                    <Link to={"/cookie"}>
                                        Cookie Policy
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="py-8" style={{ color: "#fff" }}>
                            <h1 className="text-xl font-medium">Get in Touch</h1>
                            <div className="flex gap-8 pt-4 flex-wrap">
                                <a
                                    href="https://www.facebook.com/awetisminsights"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaFacebookF className="social-icon" />
                                </a>
                                <a
                                    href="https://www.instagram.com/awetisminsights"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaInstagram className="social-icon" />
                                </a>
                                <a
                                    href="https://www.youtube.com/@awetisminsights"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaYoutube className="social-icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
