

import React, { useEffect, useState } from "react";
import Logo from "./asserts/images/full-logo.png";
import Footer from "./asserts/images/footer.png";
import BannerLogo from "./asserts/images/terms.png";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";
import { MdPolicy, MdPrivacyTip } from "react-icons/md";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import Flag from "./asserts/images/flag.png";
import { Link } from "react-router-dom";

let container = "max-w-screen-2xl m-auto p-6";
let title = "text-6xl font-black py-10";
let sub_title = "text-4xl";



export const PrivacyCookies = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <main>
            <section className="fixed bg-black w-full z-50">
                <p className="text-2xl text-white text-center p-2 flex items-center justify-center gap-3 flex-warp">
                    <img src={Flag} alt="flag" width={30} />
                    <span>Parents who are based in India get additional 50% off</span>
                </p>
            </section>
            <section className="banner">
                <div className={container}>
                    <div className="items-center w-full flex justify-between mt-10 ">
                        <div></div>
                        <div className="max-w-52  justify-end">
                            <img src={Logo} alt="Logo" />
                        </div>
                    </div>
                    <h3
                        className={`text-white text-5xl text-center font-medium tracking-wide mb-20`}
                    >
                        Privacy Policy
                    </h3>
                </div>
            </section>
            <section>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        PRIVACY POLICY
                    </h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-xl`} >
                        This Privacy Policy (<span style={{ fontWeight: 600 }}>“Policy”</span>) is a legally binding document between persons accessing or using
                        the Awetism Insight Mobile Application (<span style={{ fontWeight: 600 }}>“App”</span>) as a visitor (<span style={{ fontWeight: 600 }}>“User”</span> or <span style={{ fontWeight: 600 }}>“You”</span>  or <span style={{ fontWeight: 600 }}>“You”</span> or <span style={{ fontWeight: 600 }}>“Your”</span> or
                        <span style={{ fontWeight: 600 }}>“Yourself”</span>) and M/s. Khushi Therapy Center (<span style={{ fontWeight: 600 }}>“Owner”</span> or <span style={{ fontWeight: 600 }}>“Khushi”</span> or <span style={{ fontWeight: 600 }}>“We”</span> or <span style={{ fontWeight: 600 }}>“Our"</span> or <span style={{ fontWeight: 600 }}>“Us”</span>).
                    </div>
                    <div className={`text-xl`}>
                        Khushi is the exclusive owner in the worldwide territory of the Awetism Insight Mobile Application
                        (<span style={{ fontWeight: 600 }}>“the App”</span> ) and is responsible for maintaining the App and providing technical support. We value
                        Your concerns about online privacy and security while browsing and accessing Our App.
                    </div>
                    <div className={`text-xl`}>
                        This Policy is a contract between You and Khushi. This Policy shall be read together with the
                        respective User Terms and Conditions available at <Link
                            style={{
                                color: "#A7C837",
                                cursor: 'pointer',
                                textDecoration: "underline"
                            }}
                            to={"/terms"}>
                            Terms&Conditions
                        </Link> and other applicable terms and conditions of Khushi. This electronic record is generated by a
                        mobile application or a computer system and does not require any physical or digital signatures.
                    </div>
                    <div className={`text-xl`} >
                        Our goal in this Policy is to be completely transparent about the Personal Data We collect, why
                        We collect it and how We use it, in order to make Your experience of browsing on Our App and
                        using Our services on this App valuable since the protection of Your personal rights when
                        processing Personal Data is an important concern for Us. We encourage You to read this Policy
                        before using this App. By using this App, You agree to the terms and conditions of this Policy and
                        the User Terms and Conditions available on this App. We process Personal Data, which is
                        collected when You visit Our App, pursuant to the statutory provisions of India, in which the data
                        controller responsible for the data processing has its registered seat.
                    </div>
                    <div className={`text-xl`} >
                        The provisions pertaining to electronic records in various applicable statutes including but not
                        limited to the Information Technology Act, 2000, as amended from time to time, and the rules
                        made thereunder are applicable. Also, the personal data collected being in digital form the
                        provisions of the Digital Personal Data Protection Act, 2023 and various other applicable statues
                        as amended/implemented/ enacted from time to time relating to the processing of digital personal
                        data in India shall be applicable.
                    </div>
                    <div className={`text-xl`} >
                        <sapn style={{ fontWeight: 600 }}>“Data Controller”</sapn> within the meaning of applicable data protection law for Your Personal Data is
                        Khushi, which operates the App that You visit.
                    </div>
                    <div className={`text-xl`} >
                        <sapn style={{ fontWeight: 600 }}>“Personal Data”</sapn> means any data about an individual who is identifiable by or in relation to such
                        data. In other words, it encompasses personal information which means any information that is
                        capable of being associated with a specific person and could be used to identify that specific
                        person whether from the data or information that We have or are likely to have access to. We do
                        not consider personal information to include information that has been anonymous or aggregated
                        so that it can no longer be used to identify a specific person.
                    </div>
                </div>
            </section>

            <section>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        When and what information does the App collects:
                    </h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-xl`} >
                        BY USING OUR APP OR ITS SERVICES, YOU CONSENT TO THE COLLECTION, STORAGE,
                        AND USE OF PERSONAL DATA (INCLUDING ANY CHANGES THERETO) FOR ANY OF THE
                        SERVICES THAT WE OFFER. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF
                        THIS POLICY, WE MAY NOT BE ABLE TO ENGAGE WITH YOU OR PROVIDE OUR SERVICES.
                        If You wish to use the App, You may be asked to provide certain Personal Data.When You
                        access/browse or use Our App or when you enter information through the  published on
                        Our App to register and create an account with the App, accept the terms of use of the App,
                        contact Us for help or submit any enquiry, or otherwise enter information on the App before or
                        during its use, We collect and store Your Personal Data which includes the type of used web
                        browser, the used operating system, the domain name of Your internet service provider, the
                        technical information regarding the visit (IP address, https/http method, https/http version,
                        https/http status code, length of the transmitted data and similar items) that is provided by You
                        from time to time. When entering information on the App, as appropriate, You may be asked to
                        enter the following information:
                    </div>

                    <div className={`text-xl`} >
                        <p>1. Personal details such as Your name, country of residence, etc.;</p>
                        <p>2. Contact information such as email address, phone number, whatsapp number, etc.;</p>
                        <p>3. Account details such as user name, unique user ID, password, etc.; and</p>
                        <p>4. Certain features on the mobile device such as contacts, calendar, gallery, etc.</p>
                    </div>
                    <div className={`text-xl`} >
                        Even if You do not create an account on Our App, We have the ability to track the usage of Our
                        App when you simply browse Our App as a visitor or as a guest. This information is not used to
                        track information about individuals who visit the App but is used to an aggregate level to customize
                        Our App, improve the online experience and plan advertising and promotions. We may
                        process/retain Your Personal Data on Our servers where the data center is located. We use
                        “Cookies” (defined under Cookie Policy at <Link
                            style={{
                                color: "#A7C837",
                                cursor: 'pointer',
                                textDecoration: "underline"
                            }}
                            to={"/cookie"}>
                            Cookie Policy
                        </Link>) or similar
                        electronic tools to gather or store information about Your preferences. By doing so, We aim to
                        provide You with a safe, efficient, smooth and customized experience; provide services and
                        features that might cater to Your needs; and provide You the services of Your choice. Therefore,
                        most importantly while doing so We collect Personal Data from You that We consider necessary
                        in achieving this purpose. You may also choose not to provide Us with such Personal Data or
                        withdraw Your consent at any time.
                    </div>
                </div>
            </section>

            <section>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        Use of Your Personal Data
                    </h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-xl`} >
                        We process the Personal Data as provided by You, with Your consent, to make Our App and
                        services therein available to You, for the proper administration of the App, improving the App,
                        management of Our customer relationships and/or performing any act under the agreement
                        between You and Us, at Your request.
                    </div>
                    <div className={`text-xl`} >
                        We may collect some of the data/ information directly from You via Our App. However, We do not
                        collect any Personal Data about You from any other sources. Any of the information We collect
                        from You may be used in various ways, including but not limited to the following purposes:
                    </div>
                    <div className={`text-xl`} >
                        <p>1. Provide, operate, and maintain Our App and services,</p>
                        <p>2. Improve, personalize, and expand Our App,</p>
                        <p>3. Understand and analyze how You use Our App,</p>
                        <p>4. Create and manage user accounts,</p>
                        <p>5. Improve and develop new products, services, features, and functionality of the App,</p>
                        <p>6. Communicate with You, either directly or through one of Our representative(s), to provide
                            you with updates and other information relating to the App,</p>
                        <p>7. Send emails for marketing and promotional purposes, and</p>
                        <p>8. Find and prevent fraud.</p>
                    </div>
                </div>
            </section>

            <section>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        Purpose Limitation of the Use of Personal Data</h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-xl`} >
                        <p>Your Personal Data is only used for the purpose for which You have provided the said data to Us.</p>
                    </div>

                </div>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        Forwarding of Personal Data</h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-xl`} >
                        The aforementioned Personal Data processing is partly provided by data processors (e.g.
                        agencies for the development, design, operation and supervision of Our App, service providers
                        for sending updates/ notification/ emails, etc.). These are bound by contracts and obliged to only
                        process the data pursuant to Our instructions and do not pursue any personal interests or
                        purposes with the processing.
                    </div>

                    <div className={`text-xl`} >
                        A transmission to state institutions and authorities will only be carried out within the scope of
                        obligatory national legal regulations.
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Data Storage And Erasure
                    </div>
                    <div className={`text-xl`} >
                        Your data will only be stored as long as necessary for the stated purposes and shall thereafter be
                        erased from the server(s). In addition, storage will only be carried out in case of statutory or
                        contractual storage obligations. Any or all part of Your Personal Data will not be retained by Us,
                        after deleting or returning it. However, We may retain Your Personal Data, if We are required to
                        retain copies of all or any of the Personal Data to the extent as may be required by applicable laws
                        and only to the extent and for such period as may be required by applicable laws. The provisions
                        of this Policy will continue to apply in respect of any Personal Data retained during the duration of
                        such retention.
                    </div>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Your Rights
                    </div>
                    <div className={`text-xl`} >
                        Besides the right to information regarding Your Personal Data and rectification of Your Personal
                        Data, You have the right in the stipulated statutory framework - insofar as this is not opposed by
                        any statutory regulations - to erasure or blocking of Your Personal Data as well as a right to object to the processing of Your Personal Data. Furthermore, You have a right, also in the statutory
                        framework, to data portability.
                    </div>
                    <div className={`text-xl`} >
                        Insofar as We collect and process Your Personal Data owing to Your consent You moreover have
                        the right to revoke the consent granted by You. Your revocation will have no effect on the
                        lawfulness of the data processing carried out owing to the consent until the revocation.
                    </div>
                    <div className={`text-xl`} >
                        If necessary, We must verify Your identity before We can process Your applications. Should,
                        despite Our efforts to ensure data accuracy and actuality false information have been stored, We
                        will rectify this after a corresponding indication.
                    </div>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Steps the Company undertakes to ensure security of the Personal Data                    </div>
                    <div className={`text-xl`} >
                        You can access Your Personal Data on the App through the login id/ user name and password
                        generated at the time of Your registration and creation of the Account on Our App. We
                        recommend that You do not share Your password / OTP of Your registered Account with any third
                        person. In addition, Your Personal Information is stored on a secure server that only selected
                        personal contractors and authorized agencies have access to. Your Personal Data is protected
                        through commercially reasonable security measures and procedures in order to avoid accidental
                        or wilful manipulation, loss, destruction or access by unauthorised persons.. However, you
                        understand and accept that no data or information transmitted over the internet can be
                        guaranteed to be completely secure. We cannot ensure or warrant the security of any information
                        that you transmit to Us and you do so at Your own risk.
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Sharing of Your Personal Data with any third parties
                    </div>
                    <div className={`text-xl`} >
                        We respect and understand that You do not want Your Personal Data to be shared with other
                        companies or third parties and therefore, We are not in the business of selling it to others. We
                        share information only as described below and with Our group and affiliates, if any, who are
                        subject to this Policy. We keep Your Personal Data that You provide to Us confidential and limit
                        access to it, to those who specifically need to conduct any business activities, except as otherwise
                        permitted by the applicable law in force.
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Third-party service providers
                    </div>
                    <div className={`text-xl`} >
                        We may employ other companies and individuals to perform the functions related to the App on
                        Our behalf. This may include but not be limited to delivering e-mails, analyzing data, providing
                        marketing assistance, providing search results, providing customer service, etc. They have access
                        to Personal Data needed to perform their functions, but not use it for any other purposes. Further,
                        they must process the Personal Data in accordance with this Policy and the Applicable Law(s) in
                        force. We will share Your Personal Data internally with those staff members, who need to complete
                        Your registration as a User.
                    </div>
                    <div className={`text-xl`} >
                        Our App may include links to websites of other providers who have their own privacy policies and
                        data collection, use and disclosure practices. If You use the link of any such website or with any other user, We urge You to review their privacy policy to understand their procedures for
                        collecting, using, and disclosing personal information. At the time when the link was set in the
                        App, We verified that the directly linked sites were free of illegal contents. We have however no
                        influence on the contents of the linked sites and cannot control and monitor these continuously.
                        Therefore, We do not assume any liability for the contents of the linked sites, misuse of any
                        information by any of the websites that We may link or any other user that You connect with. This
                        Policy does not apply to the linked websites of other providers.
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Protection of the Personal Data
                    </div>
                    <div className={`text-xl`} >
                        We release Account details and other Personal Information when We believe the release is
                        appropriate to comply with the law; enforce or apply Our User Terms and Conditions, or protect
                        the rights, property or safety of the App. This includes exchanging information with other
                        companies, organizations, government or regulatory authorities for fraud protection and risk
                        reduction but excludes any selling, sharing or otherwise disclosing personally identifiable
                        information from customers for commercial purposes in a way that is contrary to the commitments
                        made in this Policy. We will share all information We collect from You, including Personal
                        Information with any third party, if We, in good faith, believe is required for the purposes of Our
                        compliance with any applicable law, regulation, legal process or enforceable governmental
                        request/action or any manner required for the purposes of protecting Our rights or interests.
                    </div>
                    <div className={`text-xl`} >
                        You will receive notice of usage of Your personal Data that might go to third parties and You will
                        have an opportunity to choose not to share the information. However, if Your Personal Data is
                        ordered by the Government and/or other judicial bodies and/or other law enforcement agencies
                        as required under the applicable laws for time being in force in India and in order to comply with
                        the legal obligations (without Your prior permission).
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Social Media (Features) and Widgets
                    </div>
                    <div className={`text-xl`} >
                        Our App includes Social Media Features, such as buttons of Facebook, Instagram, X, and Widgets
                        such as the ‘Share This Button’ or interactive mini-programs that run on Our App. These Features
                        may collect Your IP address, which page You are visiting on Our App, and may set a cookie to
                        enable the Feature to function properly. Social Media Features and Widgets are either hosted by
                        a third party or hosted directly on Our App. Your interactions with these Features are governed
                        by the privacy policy of the company providing it.
                    </div>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Updating Your Account information
                    </div>
                    <div className={`text-xl`} >
                        You could at any time update Your Account information, by logging into Your Account under the
                        tab “My Account” on the App and edit the specific information You wish to edit/update.
                    </div>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        General Matters
                    </div>
                    <div className={`text-xl`} >
                        <span style={{ textDecoration: 'underline' }}>Responsibility for Accuracy and Confidentiality of Your Account Information:</span>  You are responsible
                        for maintaining the secrecy and accuracy of Your password, email address, and other Account
                        information at all times. We recommend a strong password that You do not use with other services. We are not responsible for personal data transmitted to a third party as a result of an
                        incorrect email address provided by You.
                    </div>
                    <div className={`text-xl`} >
                        <span style={{ textDecoration: 'underline' }}>Security:</span> We use commercially reasonable security measures to protect the loss, misuse, and
                        alteration of the information under Our control. However, We cannot absolutely guarantee the
                        protection of any information shared with us.
                    </div>
                    <div className={`text-xl`} >
                        <span style={{ textDecoration: 'underline' }}>Policy Changes:</span>  We may update this Policy to reflect changes to Our information practices. If We
                        make any change in how We use Your Personal Information, We will notify You by means of a
                        notice on this App prior to the change becoming effective. We encourage You to periodically
                        review this page for the latest information on Our privacy practices. Your continued use of the
                        App and Our services after We post any modifications to the Policy on this page will constitute
                        Your acknowledgment of the modifications and Your consent to abide and be bound by the
                        modified Policy.
                    </div>
                    <div className={`text-xl`} >
                        <span style={{ textDecoration: 'underline' }}>Legal Compliance:</span>  This App complies fully with all the applicable and relevant laws of India. We
                        may disclose all or part of Your personal details in response to a request from the law enforcement
                        authorities or in a case of bona fide requirement to prevent an imminent breach of any applicable
                        law for the time being in force in India. Further, it is irrevocably and unconditionally agreed that
                        the courts of [Mumbai], India shall have exclusive jurisdiction to entertain any proceedings in
                        relation to any disputes arising out of the same.
                    </div>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Grievance Redressal Officer
                    </div>
                    <div className={`text-xl`} >
                        Users who are uncertain about what information is mandatory or have general data protection-
                        related questions or who would like Us to delete any Personal Data or information provided, or
                        have any complaints about the processing of Your Personal Data, please contact Our data privacy
                        officer at the following address or write to Us at <a style={{ textDecoration: "underline" }} href="mailto:info@awetisminsights.com">
                            info@awetisminsights.com
                        </a>.
                    </div>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        M/s. Khushi Therapy Center
                    </div>
                </div>
                {/* <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Cancellation policy for Trainings.
                    </div>
                    <div className={`text-xl`} >
                        <p>1. In the event of cancellation by therapist, if the training is for a single day then either refund or rescheduling will be done.</p>
                        <p>2. If the training is multiday and if whole training is getting cancelled by therapist then either refund or rescheduling will be done.</p>
                        <p>3. If the training is multiday and cancellation is done for a some specific days then those many days will be rescheduled. There will be no refund in this case.</p>
                        <p>4. If the parent cancels on same day or no show then there will be no refund or no rescheduling will be done.</p>
                        <p>5. If the parent cancels his training at least 15 days prior to training date then he will be given full refund after that refund will be given only if someone else registers instead of him.</p>
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Cancellation policy for recorded courses.
                    </div>
                    <div className={`text-xl`} >
                        <p>1. Cancellation and refund is not possible once the course is purchased.</p>
                    </div>

                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-2xl`} >
                        Cancellation policy for books and therapy swing.
                    </div>
                    <div className={`text-xl`} >
                        <p>1. If we are unable to ship the product then we provide full refund.</p>
                        <p>2. If the customer cancels the order before shipping then refund initiated after deducting 5% processing charges.</p>
                        <p>3. No refund will be allowed after that.</p>
                        <p>4. If the faulty or damaged product is received then we ship another product after receiving faulty product.</p>
                    </div>

                </div>  */}
            </section>

            <section style={{ background: "#000" }} className="mt-20">
                <div className={`${container} py-36`}>
                    <div className="flex justify-between flex-wrap">
                        <a href="/">
                            <div className="max-w-52 py-8">
                                <img src={Logo} alt="Logo" />
                            </div>
                        </a>
                        <div className="py-8" style={{ color: "#fff" }}>
                            <h1 className="text-xl font-medium">Contact us</h1>
                            <div className="text-xl pt-4 flex gap-2 items-center">
                                <IoMailSharp />
                                <a href="mailto:info@awetisminsights.com">
                                    info@awetisminsights.com
                                </a>
                            </div>
                        </div>
                        <div className="py-8" style={{ color: "#fff" }}>
                            <h1 className="text-xl font-medium">Policy</h1>
                            <div className="flex flex-col">
                                <div className="text-xl pt-4 flex gap-2 items-center">
                                    <IoMailSharp />
                                    <Link to={"/terms"}>
                                        Terms & Conditions
                                    </Link>
                                </div>
                                <div className="text-xl pt-4 flex gap-2 items-center">
                                    <MdPrivacyTip />
                                    <Link to={"/privacy"}>
                                        Privacy Policy
                                    </Link>
                                </div>
                                <div className="text-xl pt-4 flex gap-2 items-center">
                                    <MdPolicy />
                                    <Link to={"/cookie"}>
                                        Cookie Policy
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="py-8" style={{ color: "#fff" }}>
                            <h1 className="text-xl font-medium">Get in Touch</h1>
                            <div className="flex gap-8 pt-4 flex-wrap">
                                <a
                                    href="https://www.facebook.com/awetisminsights"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaFacebookF className="social-icon" />
                                </a>
                                <a
                                    href="https://www.instagram.com/awetisminsights"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaInstagram className="social-icon" />
                                </a>
                                <a
                                    href="https://www.youtube.com/@awetisminsights"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaYoutube className="social-icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
