import React from "react";
import Logo from "./asserts/images/full-logo.png";
import Ques1 from "./asserts/images/ques1.png";
import Ques2 from "./asserts/images/ques2.png";
import Ques3 from "./asserts/images/ques3.png";
import AboutImg from "./asserts/images/about-img.png";
import ExpertImg from "./asserts/images/expert.png";
import CommunityImg from "./asserts/images/community.png";
import bannerImg from "./asserts/images/bannerImg.png";
import learnImg from "./asserts/images/learn.png";
import platformImg from "./asserts/images/platform.png";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";
import { Button } from "./components/button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { MdPolicy, MdPrivacyTip } from "react-icons/md";
import PlayStore from "./asserts/images/playstore.png";
import AppStore from "./asserts/images/appstore.png";

let container = "max-w-screen-2xl m-auto p-3";

export const LanunchingPage = () => {
  const apiKey = process.env?.REACT_APP_FIREBASE_API_URL;

  console.log(apiKey);
  return (
    <main>
      <section className="banner">
        <div className={`${container}`}>
          <div className="flex justify-between con-hide">
            <div className="w-32">
              <img src={Logo} alt="Logo" />
            </div>
            <a href="https://app.awetisminsights.com/login/" alt="login">
              <Button label={"login"} />
            </a>
          </div>
          <div className="w-full flex justify-end hide">
            <div className="max-w-52">
              <a href="https://app.awetisminsights.com/login/" alt="login">
                <Button label={"login"} />
              </a>
            </div>
          </div>
          <div className="text-center ">
            <div className="flex justify-center mb-6 hide">
              <div className="max-w-52">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
            <p
              className="text-4xl pb-6 main-text main-hide"
              style={{ lineHeight: "50px" }}
            >
              Get expert Occupational therapy support and guidance for your
              child diagnosed with Autism (18 months to 18 years)....
            </p>
            {/* <div
              className={`${container} flex justify-center items-center xs:flex-wrap`}
            >
              <p
                className="text-2xl pb-6 main-text"
                style={{ lineHeight: "40px" }}
              >
                Get <span className="font-semibold text-[#fff]">Reena's</span>{" "}
                23+ years of expertise through daily insights, deep-dive
                bi-monthly live sessions, and a treasure trove of resources—all
                without the hassle of separate fees or waitlists. Plus, become
                part of a vibrant community of parents sharing your journey,
                offering mutual support and shared solutions.
              </p>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div
          className={`${container} flex sm:flex-col lg:flex-row justify-between items-center xs:flex-wrap`}
          id="ques"
        >
          <div>
            <div className="text-center">
              <p className={`text-4xl font-medium mb-8 ques-heading`}>
                Is this You ?
              </p>
            </div>
            <div className="text-2xl mb-6 flex items-center" id="ques-symbol1">
              <div
                style={{
                  color: "#A7C836",
                }}
                className="mr-2 text-xl w-10"
                id="ques-symbols"
              >
                <div className="w-12">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.35"
                      d="M5 14.5s1.5 0 3.5 3.5c0 0 5.559-9.167 10.5-11"
                      color="currentColor"
                    />
                  </svg>
                </div>
              </div>
              <p>Are you the parent of a child diagnosed with Autism ?</p>
            </div>
            <div className="text-2xl mb-6 flex " id="ques-symbol1">
              <div
                style={{
                  color: "#A7C836",
                }}
                className="mr-2 text-xl w-14"
                id="ques-symbols"
              >
                <div className="w-12">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.35"
                      d="M5 14.5s1.5 0 3.5 3.5c0 0 5.559-9.167 10.5-11"
                      color="currentColor"
                    />
                  </svg>
                </div>
              </div>
              <p>
                Do you often find yourself feeling overwhelmed by the daily
                challenges—whether it’s managing self-stimulatory behaviours,
                handling meltdowns, or figuring out the best sensory diet for
                your child ?
              </p>
            </div>
          </div>
          {/* <div className={`p-10 rounded-lg custom-slide flex gap-4`}>
            <div className="flex flex-col">
              <div className="max-w-md mb-4">
                <img src={Ques1} alt="icon" className="rounded-xl" />
              </div>
              <div className="max-w-md">
                <img src={Ques3} alt="icon" />
              </div>
            </div>
            <div className="max-w-md">
              <img src={Ques2} alt="icon" />
            </div>
          </div> */}
        </div>
      </section>

      <section>
        <div className={`${container} flex flex-col items-center xs:flex-wrap`}>
          <p className={`text-4xl font-medium mb-8 ques-heading`}>
            This Program is for You If
          </p>
          <div className="w-full">
            <div className="flex gap-10 flex-wrap justify-center main-pro">
              {[
                "You're exhausted from endless Googling, trying to find answers about your child's behaviors",
                "You feel lost in prioritizing OT goals and need clarity on what progress to expect",
                "You face resistance when implementing sensory diets and need strategies that work",
                "You want to distinguish between behavioural and sensory challenges",
                "You’re overwhelmed by intense emotions and worries about your child’s future",
                "You want to better understand your child’s non-verbal cues and adapt your strategies accordingly",
                "You’re struggling with sleep, toilet training, or oral motor challenges",
                "You’re trying therapies but aren’t seeing progress, feeling confused about the why behind them",
                "You want to partner with therapists, knowing that you understand your child best",
              ].map((arr, i) => (
                <div
                  style={{ border: "1px solid #A7C836" }}
                  id="box"
                  className="border px-8 py-4 max-w-md mb-6 rounded-md hover:shadow-lg flex gap-2 items-center"
                >
                  <p
                    className="border text-2xl"
                    style={{
                      borderRadius: "50%",
                      padding: "10px 20px",
                      flex: "0 0 1%",
                      height: "54px",
                      border: "1px solid #A7C836",
                    }}
                  >
                    {i + 1}
                  </p>
                  <p style={{ flex: 1 }} className="text-xl">
                    {arr}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* <section className="banner">
        <div className={`${container}`}>
          <h1 className="text-white text-center text-3xl font-medium py-8">
            Here is what you get
          </h1>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={120}
            slidesPerView={3}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              639: {
                slidesPerView: 1,
              },
              865: {
                slidesPerView: 2,
              },
              1000: {
                slidesPerView: 2,
              },
              1300: {
                slidesPerView: 3,
              },
              1700: {
                slidesPerView: 3,
              },
            }}
            navigation
          >
            <SwiperSlide>
              <div
                className="p-10 my-10 rounded-lg custom-slide"
                style={{
                  width: "380px",
                  border: "2px solid #D3EAC8",
                  height: "20rem",
                }}
              >
                <div className="">
                  <div className="max-w-20">
                    <img src={ExpertImg} alt="icon" />
                  </div>
                </div>
                <div className="text-2xl font-medium py-4 text-white">
                  Immediate Expert Access
                </div>
                <div className="text-xl text-white">
                  Say goodbye to waiting lists and hello to immediate support.
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="p-10 my-10 rounded-lg custom-slide"
                style={{
                  width: "380px",
                  border: "2px solid #D3EAC8",
                  height: "20rem",
                }}
              >
                <div className="">
                  <div className="max-w-20">
                    <img src={platformImg} alt="icon" />
                  </div>
                </div>
                <div className="text-2xl font-medium py-4 text-white">
                  All-In-One Platform
                </div>
                <div className="text-xl text-white">
                  Enjoy masterclasses, courses, and community support, all
                  bundled into one seamless membership.
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="p-10 my-10 rounded-lg custom-slide"
                style={{
                  width: "380px",
                  border: "2px solid #D3EAC8",
                  height: "20rem",
                }}
              >
                <div className="">
                  <div className="max-w-20">
                    <img src={learnImg} alt="icon" />
                  </div>
                </div>
                <div className="text-2xl font-medium py-4 text-white">
                  Consistent Learning
                </div>
                <div className="text-xl text-white">
                  Stay engaged with daily insights which helps you to build
                  consistency and momentum.
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="p-10 my-10 rounded-lg custom-slide"
                style={{
                  width: "380px",
                  border: "2px solid #D3EAC8",
                  height: "20rem",
                }}
              >
                <div className="">
                  <div className="max-w-20">
                    <img src={CommunityImg} alt="icon" />
                  </div>
                </div>
                <div className="text-2xl font-medium py-4 text-white">
                  Empowering Community
                </div>
                <div className="text-xl text-white">
                  You're not alone. Our supportive network of parents and
                  experts will make your journey smoother.
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="p-10 my-10 rounded-lg custom-slide"
                style={{
                  width: "380px",
                  border: "2px solid #D3EAC8",
                  height: "20rem",
                }}
              >
                <div className="text-xl text-white">
                  Transformation of you as a parent and in your child as you
                  leverage a holistic approach perfected over 23 years, blending
                  expert advice, community support, and continuous learning for
                  the ultimate care strategy.
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <h1 className="text-3xl text-center py-4 text-white">
            Join us now and take the first step to navigate your journey with
            clarity and calmness.
          </h1>
        </div>
      </section> */}
      <section className="m-8">
        <div className={`${container} banner rounded-3xl p-16 relative`}>
          <div className="flex justify-center items-center gap-10">
            {/* <div className="max-w-md w-[50%] logo">
              <img src={Footer} alt="img" />
            </div> */}
            <div className="w-[60%] reg-form">
              <div className="w-full text-center pb-2">
                <h3 className="text-2xl text-gray-950 join-us">
                  Our team is dedicated to providing you with daily insights,
                  detailed masterclasses and courses so that you accelerate
                  developmental gains...
                </h3>
                <h3 className="text-2xl text-gray-950 join-us">
                  Get live mentoring every week by our Occupational therapists.
                </h3>
              </div>
              <div className="text-center">
                <a href="https://app.awetisminsights.com/login/" alt="login">
                  <Button label={"Join us"} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-6">
        <div className={`${container} flex flex-col justify-center`}>
          <p className="text-3xl main-text" style={{ lineHeight: "40px" }}>
            Awetism Insights is the brainchild of Reena Singh, a dedicated
            Occupational Therapist who has spent 24 years empowering parents
            like you.
          </p>
          <div className="flex justify-center py-6">
            <div className="max-w-xl">
              <img src={AboutImg} alt="Logo" className="rounded-lg" />
            </div>
          </div>
          <p className="text-3xl pb-6 main-text" style={{ lineHeight: "40px" }}>
            Reena’s approach is holistic, combining complementary therapies such
            as primitive reflex integration, Safe and Sound Protocol,
            therapeutic listening, Touch for Health (energy kinesiology),
            emotional freedom techniques, and neurolinguistic programming, all
            integrated with traditional Occupational Therapy.
          </p>
        </div>
      </section>

      <section>
        <div className={`${container} flex flex-col items-center mb-5`}>
          <p className={`text-4xl font-medium mb-8 ques-heading`}>
            Download Our Latest App on Your Device
          </p>
          <div className="flex justify-center gap-4">
            <div className="w-1/4 sm:w-1/6">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/search?q=awetism+insights&c=apps&hl=en"
              >
                <img
                  src={PlayStore}
                  alt="Play Store"
                  className="rounded-lg w-full h-auto"
                />
              </a>
            </div>
            <div className="w-1/4 sm:w-1/6">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/in/app/awetism-insights/id6499340907"
              >
                <img
                  src={AppStore}
                  alt="App Store"
                  className="rounded-lg w-full h-auto"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="banner ">
        <div className={`${container}`}>
          <div className="flex justify-between flex-wrap">
            <a href="/">
              <div className="max-w-52 py-8">
                <img src={Logo} alt="Logo" />
              </div>
            </a>
            <div className="py-5">
              <h1 className="text-xl font-medium">Contact us</h1>
              <div className="text-xl pt-2 flex gap-2 items-center">
                <IoMailSharp />
                <a href="mailto:info@awetisminsights.com">
                  info@awetisminsights.com
                </a>
              </div>
            </div>
            <div className="py-5">
              <h1 className="text-xl font-medium">Policy</h1>
              <div className="flex flex-col">
                <div className="text-xl pt-2 flex gap-2 items-center">
                  <IoMailSharp />
                  <Link to={"/terms"}>Terms & Conditions</Link>
                </div>
                <div className="text-xl pt-2 flex gap-2 items-center">
                  <MdPrivacyTip />
                  <Link to={"/privacy"}>Privacy Policy</Link>
                </div>
                <div className="text-xl pt-2 flex gap-2 items-center">
                  <MdPolicy />
                  <Link to={"/cookie"}>Cookie Policy</Link>
                </div>
              </div>
            </div>
            <div className="py-5">
              <h1 className="text-xl font-medium">Get in Touch</h1>
              <div className="flex gap-8 pt-2 flex-wrap">
                <a
                  href="https://www.facebook.com/awetisminsights"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF className="social-icon" />
                </a>
                <a
                  href="https://www.instagram.com/awetisminsights"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="social-icon" />
                </a>
                <a
                  href="https://www.youtube.com/@awetisminsights"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube className="social-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
