import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { LanunchingPage } from "./launching";
import 'swiper/css';
import { TermsCondtios } from "./terms&condition";
import { PrivacyCookies } from "./privacyPolicy";
import { CookiePolicy } from "./cookiePolicy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LanunchingPage />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/terms" element={<TermsCondtios />} />
        <Route path="/privacy" element={<PrivacyCookies />} />
        <Route path="/cookie" element={<CookiePolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
