

import React, { useEffect, useState } from "react";
import Logo from "./asserts/images/full-logo.png";
import Footer from "./asserts/images/footer.png";
import BannerLogo from "./asserts/images/terms.png";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";
import { MdPolicy, MdPrivacyTip } from "react-icons/md";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import Flag from "./asserts/images/flag.png";
import { Link } from "react-router-dom";

let container = "max-w-screen-2xl m-auto p-6";
let title = "text-6xl font-black py-10";
let sub_title = "text-4xl";



export const CookiePolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <main>
            <section className="fixed bg-black w-full z-50">
                <p className="text-2xl text-white text-center p-2 flex items-center justify-center gap-3 flex-warp">
                    <img src={Flag} alt="flag" width={30} />
                    <span>Parents who are based in India get additional 50% off</span>
                </p>
            </section>
            <section className="banner">
                <div className={container}>
                    <div className="items-center w-full flex justify-between mt-10 ">
                        <div></div>
                        <div className="max-w-52  justify-end">
                            <img src={Logo} alt="Logo" />
                        </div>
                    </div>
                    <h3
                        className={`text-white text-5xl text-center font-medium tracking-wide mb-20`}
                    >
                        Cookie Policy
                    </h3>
                </div>
            </section>
            <section>
                <div
                    className={`${container} flex  items-center pt-10 xs:flex-wrap`}
                >
                    <h1 className="text-center text-3xl font-medium py-3">
                        COOKIE POLICY
                    </h1>
                </div>
                <div className={`${container} flex  flex-col gap-6`} >
                    <div className={`text-xl`} >
                        <span style={{ fontWeight: 600 }}>“Cookies”</span> are small data files on certain pages of the App to help analyze Our App page flow,
                        measure promotional effectiveness and promote trust and safety. Cookies are placed on your
                        mobile that assist Us in providing Our services to You. We offer certain features that are only
                        available through the use of a “Cookie”.
                    </div>
                    <div className={`text-xl`} >
                        We use “session” cookies to keep You logged in while You Use Our services on the App (browsing
                        through the App, registering, and creating an Account) to better understand how You interact with
                        Our services, and to monitor aggregate usage and data traffic information on Our services. We
                        use “persistent” cookies to recognize each time You return to Our services. For example, We
                        create a persistent cookie that includes some basic information about what You like, your most
                        recent search and our preferences. We use this persistent cookie to remember Your preference
                        and, if You register and create an Account, to make Your User experience consistent after You
                        register.
                    </div>
                    <div className={`text-xl`} >
                        Most internet browsers automatically accept cookies, but You can change Your settings or use
                        third-party tools to refuse cookies or prompt You before accepting cookies from the App You visit.
                        You can also use Your browser settings or other tools to delete cookies You already have. Please
                        be aware that some parts of Our services may not work for You if You disable cookies.
                    </div>
                </div>
            </section>

            <section style={{ background: "#000" }} className="mt-20">
                <div className={`${container} py-36`}>
                    <div className="flex justify-between flex-wrap">
                        <a href="/">
                            <div className="max-w-52 py-8">
                                <img src={Logo} alt="Logo" />
                            </div>
                        </a>
                        <div className="py-8" style={{ color: "#fff" }}>
                            <h1 className="text-xl font-medium">Contact us</h1>
                            <div className="text-xl pt-4 flex gap-2 items-center">
                                <IoMailSharp />
                                <a href="mailto:info@awetisminsights.com">
                                    info@awetisminsights.com
                                </a>
                            </div>
                        </div>
                        <div className="py-8" style={{ color: "#fff" }}>
                            <h1 className="text-xl font-medium">Policy</h1>
                            <div className="flex flex-col">
                                <div className="text-xl pt-4 flex gap-2 items-center">
                                    <IoMailSharp />
                                    <Link to={"/terms"}>
                                        Terms & Conditions
                                    </Link>
                                </div>
                                <div className="text-xl pt-4 flex gap-2 items-center">
                                    <MdPrivacyTip />
                                    <Link to={"/privacy"}>
                                        Privacy Policy
                                    </Link>
                                </div>
                                <div className="text-xl pt-4 flex gap-2 items-center">
                                    <MdPolicy />
                                    <Link to={"/cookie"}>
                                        Cookie Policy
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="py-8" style={{ color: "#fff" }}>
                            <h1 className="text-xl font-medium">Get in Touch</h1>
                            <div className="flex gap-8 pt-4 flex-wrap">
                                <a
                                    href="https://www.facebook.com/awetisminsights"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaFacebookF className="social-icon" />
                                </a>
                                <a
                                    href="https://www.instagram.com/awetisminsights"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaInstagram className="social-icon" />
                                </a>
                                <a
                                    href="https://www.youtube.com/@awetisminsights"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaYoutube className="social-icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
